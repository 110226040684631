import React from "react"
import Img from "gatsby-image"

function formatAuthors(arr, conjunction='and', ifempty=''){
    let l = arr.length;
    if (!l) return ifempty;
    if (l<2) return arr[0];
    if (l<3) return arr.join(` ${conjunction} `);
    arr = arr.slice();
    arr[l-1] = `${conjunction} ${arr[l-1]}`;
    return arr.join(", ");
}

const NonStretchedImage = props => {
  let normalizedProps = props
  if (props.fluid && props.fluid.presentationWidth) {
    normalizedProps = {
      ...props,
      style: {
        ...(props.style || {}),
        maxWidth: props.fluid.presentationWidth,
        margin: "0 auto", // Used to center the image
      },
    }
  }

  return <Img {...normalizedProps} />
}

const PublicationLink = ({ URL, name, rel }) => <a className="mr-2" rel={rel} href={URL}>[{name}]</a>;

const Publication = ({ title, abstract, author, fields: { custom: { links, image, publishedArticle }, issued }}) => (
    <div style={{ padding: '0 1rem' }}>
        <h5 className="text-underline">{title}</h5>
        <h6 className="mb-2">{formatAuthors(author.map(a => `${a.given} ${a.family}`))}</h6>
        <div>
          { image && <NonStretchedImage fluid={image.src.childImageSharp.fluid} alt="" /> }
        </div>
        <div className="mb-2">
            {publishedArticle && <PublicationLink URL={`https://doi.org/${publishedArticle.DOI}`} name="Paper" rel="nofollow" />}
            {links.map(l => <PublicationLink {...l} />)}
        </div>
        <div><small>{abstract}</small></div>
        <div className="text-right font-weight-light font-italic mt-2">
            <small>
              {issued} { publishedArticle && <>
                - {publishedArticle.event} - <a href={`https://doi.org/${publishedArticle.DOI}`} rel="nofollow">{publishedArticle.DOI}</a>
              </>}
            </small>
        </div>
        <hr />
    </div>
)

export default Publication

