import React from "react"
// import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Publication from "../components/publication"
import { graphql } from "gatsby"

const PublicationsPage = ({ data }) => (
  <Layout>
    <SEO title="Publications" keywords={[`maurice`, `quach`, `website`, `publications`]} />
    <div>
        <h4>Publications</h4>
        <hr />
        <div>
            {data.allPublicationsJson.nodes.map(x => <Publication {...x} />)}
        </div>
    </div>
  </Layout>
)

export const query = graphql`
    {
        allPublicationsJson(sort: { fields: fields___issued, order: DESC }) {
            nodes {
                title
                abstract
                URL
                author {
                    family
                    given
                }
                issued
                fields {
                    issued
                    custom {
                        links {
                            name
                            URL
                        }
                        image {
                            src {
                                childImageSharp {
                                    fluid(quality: 90) {
                                        ...GatsbyImageSharpFluid_withWebp
                                        presentationWidth
                                    }
                                }
                            }
                        }
                        publishedArticle {
                            event
                            DOI
                        }
                    }
                }
            }
        }
    }
`

export default PublicationsPage

